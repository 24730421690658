import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../../components/icons/YoutubeIcon';

import Link from '../../../../../components/Link';
import Seo from '../../../../../components/Seo';

const KupalskiPisniPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "kupalski-pisni"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Купальскі пісні"
        description="Купальські пісні — обрядові пісні, які співали на Івана Купала, біля ритуального вогнища. Це свято припадає на період літнього сонцестояння."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Купальські пісні
          </h1>
          <p className="typo-body mt-4 max-w-md">
            Купальські пісні — обрядові пісні, які співали на Івана Купала, біля ритуального вогнища.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список купальских пісень
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру купальської пісні
        </h2>
        <p className="typo-body mt-4">
          Виконання купальських пісень здійснювалося, коли наставало свято Івана Купала. Святкують його традиційно в ніч
          з 6-о на 7-е липня. Свої витоки бере з язичницьких часів. Давня суть купальських звичаїв, так само, як ймення
          “Купало” до кінця не з’ясована; із згадок та пісень, що збереглися до теперішніх часів, стає зрозумілим, що це
          торжество, яке прославляє животворні явища природи, наймогутнішої її сили — сонця (вогоню) та води.
        </p>
        <p className="typo-body mt-4">
          Підпалене колесо установлювали на довгій палиці або жердинині (використовують для підтримування високих рослин,
            підпирання гілок) або відпускали його по воді. На поляні вривали традиційне дерево, прикрашене дівчачими
            вінками, згодом їх відпускали по воді і споглядали: в яку сторону віночок буде плисти — з того боку і буде
            чекати наречений. Поряд з деревом розводили вогнище, і хлопці — бувало разом з дівчатами — перестрибували це
            вогнище. Купальський вогонь не погашали, а лишали тліти (“Лежи, лежи, Купалочку, в червоному багаттячку”).
            Традиційно вважали, що саме в купальську ніч зілля має найцілющу дію і навіть може розквітнути папороть.
            Якщо побачиш цю квітку — обов’язково будеш щасливим.
        </p>
        <p className="typo-body mt-4">
          Наприкінці ХІХ ст. вчені зазначили той факт, що купальські пісні перестали мати такий тісний зв’язок з давніми
          обрядами. В перших піснях часто звучало звернення до Купала як до живого створіння, якому властиво ночувати,
          вечеряти, купатися. В піснях описані мотиви купання, плетення вінків; чимало серед них, які стосуються
          почуттів молодих людей.
        </p>
        <p className="typo-body mt-4">
          Митців, музикантів, літераторів вабила лірична атмосфера купальської пісні та обрядів. Микола Васильович Гоголь
          створив “Вечір проти Івана Купала”, Михайло Петрович Старицький — “Ніч під Івана Купала”, Михайло Панасович
          Стельмах “На Івана Купала”.
        </p>
      </div>
    </>
  );
};

export default KupalskiPisniPage;
